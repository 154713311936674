<template>
  <section class="mailbox">
    <b-row class="overall-content d-flex justify-content-center align-items-center h-100">
      <b-row class="h-75 w-50">
        <b-col md="12" class="d-flex justify-content-center align-items-center h-100">
          <b-row class="h-100 w-100">
            <b-col md="4" class="tab-list p-3 d-flex justify-content-center align-items-center">
              <b-row>
                <div v-for="(tab, index) in tabs" :key="index" @click="selectedTab = tab.title"
                  :class="{ active: selectedTab == tab.title }" class="col-12 tab-item">
                  <h3 class="tab-item__heading">{{ tab.title }}</h3>
                  <small class="tab-item__subheading">{{ tab.subtitle }}</small>
                </div>
              </b-row>
            </b-col>

            <!-- <b-col md="1"></b-col> -->

            <b-col md="8" class="content-tab h-100 py-5">
              <template v-if="selectedTab == 'Inicio'">
                <b-row class="d-flex justify-content-center align-items-center">
                  <b-col md="10" class="text-center">
                    <h3 class="tab-content__header">
                      Bienvenido a este espacio realizado para ti, siéntete
                      libre de compartirnos tus comentarios, quejas o
                      sugerencias.
                    </h3>

                    <br /><br />

                    <p class="tab-content__text mb-4">
                      Con el ánimo de prestar la mejor atención, en Bee Telecom
                      establecemos vías de comunicación eficaces para conectar
                      la necesidad de nuestros clientes, por ello ponemos a tu
                      disposición este buzón.
                    </p>

                    <p class="tab-content__text mb-4">
                      Ten por seguro que la información que nos compartas será
                      de mucha utilidad con la finalidad de mejorar la eficacia,
                      eficiencia y calidad de los servicios que brindamos en Bee
                      Telecom.
                    </p>

                    <p class="tab-content__text mb-4">
                      Por favor, registra tus datos y, el folio de seguimiento
                      será enviado a tu correo electrónico, te pedimos revisar
                      tu bandeja de entrada. Agradecemos de antemano la
                      paciencia y el apoyo.
                    </p>
                  </b-col>
                </b-row>
              </template>

              <template v-if="selectedTab == 'Comentarios'">
                <b-container class="h-100 d-flex justify-content-center align-items-center">
                  <b-row class="comments-section w-100 h-100 d-flex justify-content-center align-items-center">
                    <b-col md="10" class="text-center">
                      <h3 class="tab-content__header text-center">
                        Dejanos un comentario
                      </h3>

                      <small>
                        Haznos llegar tus comentarios, juntos mejoraremos
                        nuestro servicio y tú serás más feliz
                      </small>

                      <b-form @submit.prevent="onSubmitComments" class="mt-3" id="formComments">
                        <b-form-group id="input-group-1" label="Nombre completo:" label-for="input-1" class="mt-2">
                          <b-form-input id="input-1" v-model="form.name"
                            placeholder="Ingrese su nombre completo"></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Correo electrónico:" label-for="input-2" class="mt-2">
                          <b-form-input id="input-2" v-model="form.email" type="email"
                            placeholder="Ingrese su correo electrónico"></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-" label="Comentario o sugerencia:" label-for="textarea"
                          class="mt-2">
                          <b-form-textarea id="textarea" v-model="form.description"
                            placeholder="Ingrese el contenido de su mensaje..." rows="3" max-rows="8"></b-form-textarea>
                        </b-form-group>

                        <b-row class="mt-4 d-flex justify-content-center">
                          <b-button type="submit" :disabled="processing" variant="btn btn-primary btn-block">
                            {{ processing ? "Enviando..." : "Enviar" }}
                          </b-button>
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
              </template>

              <template v-if="selectedTab == 'Quejas'">
                <b-container class="h-100 d-flex justify-content-center align-items-center">
                  <b-row class="comments-section w-100 h-100 d-flex justify-content-center align-items-center">
                    <b-col md="10" class="text-center">
                      <h3 class="tab-content__header text-center">
                        Compártenos tu inquietud
                      </h3>

                      <small>
                        Lamentamos la molestia que se pudo haber ocasionado,
                        dinos que paso para poder solucionarlo y mejorar
                      </small>

                      <!-- <b-form @submit="onSubmit" @reset="onReset" class="mt-3"> -->
                      <b-form @submit.prevent="onSubmitComplaint" class="mt-3" id="formComplaint">
                        <b-form-group id="input-group-2" label="Nombre completo:" label-for="input-2" class="mt-2">
                          <b-form-input id="input-2" v-model="form.name" placeholder="Ingrese su nombre completo"
                            required></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-1" label="Correo electrónico:" label-for="input-1" class="mt-2">
                          <b-form-input id="input-1" v-model="form.email" type="email"
                            placeholder="Ingresa su correo electrónico" required></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-3" label="Descripción del problema:" label-for="textarea"
                          class="mt-2">
                          <b-form-textarea id="textarea" v-model="form.description"
                            placeholder="Ingrese los detalles del inconveniente..." rows="3" max-rows="8">
                          </b-form-textarea>
                        </b-form-group>

                        <b-row class="mt-4 d-flex justify-content-center">
                          <b-button type="submit" :disabled="processing" variant="btn btn-primary btn-block">
                            {{ processing ? "Enviando..." : "Enviar" }}
                          </b-button>
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
              </template>

              <template v-if="selectedTab == 'Cancelaciones'">
                <b-container class="h-100 d-flex justify-content-center align-items-center">
                  <b-row class="comments-section w-100 h-100 d-flex justify-content-center align-items-center">
                    <b-col md="10" class="text-center">
                      <h3 class="tab-content__header text-center">
                        ¿Deseas cancelar nuestro servicio?
                      </h3>

                      <small>
                        Lamentamos que tomes está desición, estamos trabajando
                        para mejorar en los servicios que te ofrecemos.
                      </small>

                      <b-form @submit.prevent="onSubmitCancellation" class="mt-3" id="formCancellation">
                        <b-form-group id="input-group-2" label="Nombre completo:" label-for="input-2" class="mt-2">
                          <b-form-input id="input-2" v-model="form.name" placeholder="Ingrese su nombre completo"
                            required></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-1" label="Correo electrónico:" label-for="input-1" class="mt-2">
                          <b-form-input id="input-1" v-model="form.email" type="email"
                            placeholder="Ingresa su correo electrónico" required></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Número de cliente:" label-for="input-2" class="mt-2">
                          <b-form-input id="input-2" v-model="form.clientId" placeholder="Ingrese su número de cliente"
                            required></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-3" label="Razón de cancelación:" label-for="textarea" class="mt-2">
                          <b-form-textarea id="textarea" v-model="form.description"
                            placeholder="Ingresa una breve explicación con los motivos..." rows="3" max-rows="8">
                          </b-form-textarea>
                        </b-form-group>

                        <b-row class="mt-4 d-flex justify-content-center">
                          <button type="submit" :disabled="processing" class="btn btn-primary btn-block">
                            {{ processing ? "Enviando..." : "Enviar" }}
                          </button>
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
              </template>

              <template v-if="selectedTab == 'Localiza tu folio'">
                <b-container class="h-100">
                  <b-row class="folio-locator d-flex justify-content-center align-items-center"
                    :class="{ 'h-100': resultData === '' }">
                    <b-col md="10" class="">
                      <h3 class="tab-content__header text-center">
                        Seguimiento de folios
                      </h3>

                      <b-form @submit.prevent="onLoadGetFolio" class="mt-3">
                        <b-form-group id="input-group-1" label="Folio de seguimiento:" label-for="input-1">
                          <b-form-input id="input-1" v-model="form.folio" type="text" placeholder="Ingrese su folio"
                            required></b-form-input>
                        </b-form-group>

                        <b-row class="mt-3 d-flex justify-content-center">
                          <button type="submit" :disabled="processing" class="btn btn-primary btn-block">
                            {{ processing ? "Buscando..." : "Localizar" }}
                          </button>
                        </b-row>
                      </b-form>
                    </b-col>

                    <b-col md="10" class="mt-3 content-tab-scroll">
                      <b-row class="d-flex justify-content-center align-items-center" v-if="onLoadFolio === true">
                        <b-col md="3" class="flip-to-square">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </b-col>
                      </b-row>

                      <div class="container" v-if="resultData !== ''">
                        <b-row class="bs-wizard">
                          <b-col md="4" class="bs-wizard-step complete" :class="{ active: resultData.status === 1 }">
                            <div class="text-center bs-wizard-stepnum">
                              RECIBIDO
                            </div>

                            <div class="progress">
                              <div class="progress-bar"></div>
                            </div>

                            <a class="bs-wizard-dot"></a>

                            <div class="text-center bs-wizard-info">
                              {{ resultData.created }}
                            </div>
                          </b-col>

                          <b-col md="4" class="bs-wizard-step" :class="{
                            disabled: resultData.status < 2,
                            complete: resultData.status >= 2,
                            active: resultData.status === 2,
                          }">
                            <div class="text-center bs-wizard-stepnum">
                              EN PROCESO
                            </div>

                            <div class="progress">
                              <div class="progress-bar"></div>
                            </div>

                            <a class="bs-wizard-dot"></a>

                            <div class="text-center bs-wizard-info">
                              {{ resultData.proccess_date }}
                            </div>
                          </b-col>

                          <b-col md="4" class="bs-wizard-step" :class="{
                            disabled: resultData.status < 3,
                            complete: resultData.status === 3,
                            active: resultData.status === 3,
                          }">
                            <div class="text-center bs-wizard-stepnum">
                              FINALIZADO
                            </div>

                            <div class="progress">
                              <div class="progress-bar"></div>
                            </div>

                            <a class="bs-wizard-dot"></a>

                            <div class="text-center bs-wizard-info">
                              {{ resultData.response_date }}
                            </div>
                          </b-col>
                        </b-row>

                        <b-row>
                          <p class="tab-content__text text-center mt-3">
                            Respuesta del sistema: <br />

                            <strong v-if="resultData.status === 1">
                              El ticket ha sido recibido, gracias por
                              contactarnos.
                            </strong>

                            <strong v-if="resultData.status === 2">
                              El ticket ya ha sido asignado a un agente, te
                              informaremos sobre la resolución del tema.
                            </strong>

                            <strong v-if="resultData.status === 3">
                              {{ resultData.response }}
                            </strong>
                          </p>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-row>

    <div class="area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </section>
</template>

<script>
import axios from "axios";

axios.defaults.withCredentials = true;
// axios.defaults.baseURL = "https://buzon.kiwinetworks.com";
axios.defaults.baseURL = "http://45.169.236.23";
// axios.defaults.baseURL = "https://google.com";

export default {
  name: "Mailbox",

  data() {
    return {
      selectedTab: "Inicio",
      tabs: [
        {
          title: "Inicio",
          subtitle: "Canales de reporte",
        },
        {
          title: "Comentarios",
          subtitle: "Compartenos tu opinión",
        },
        {
          title: "Quejas",
          subtitle: "Reporta malas prácticas",
        },
        {
          title: "Cancelaciones",
          subtitle: "Da de baja servicios",
        },
        {
          title: "Localiza tu folio",
          subtitle: "Revisa tu proceso",
        },
      ],

      form: {
        folio: "",
        type: "",
        name: "",
        email: "",
        clientId: "",
        description: "",
      },

      processing: false,
      onLoadFolio: false,
      processingComplaint: false,
      show: true,
      resultData: "",
    };
  },

  methods: {
    resetInput() {
      this.form.folio = "";
      this.form.type = "";
      this.form.name = "";
      this.form.email = "";
      this.form.clientId = "";
      this.form.description = "";
    },

    selectTab() {
      this.selectedTab = this.tab.title;
      this.resetInput();
    },

    onLoadGetFolio() {
      this.resultData = "";
      this.processing = true;
      this.onLoadFolio = true;

      setTimeout(this.onGetFolio, 3000);
    },

    onGetFolio() {
      axios
        .get(`/api/search-ticket/${this.form.folio}`)
        .then((result) => {
          console.log(result);
          this.resultData = result.data[0].content;
        })
        .catch((error) => {
          console.log(error);

          this.$swal({
            icon: "warning",
            title:
              "El folio que ingreso no se encuentra registrado, intentelo nuevamente.",
          });

          this.processing = false;
          this.form.folio = "";
        })
        .finally(() => {
          this.processing = false;
        });

      this.onLoadFolio = false;
    },

    onSubmitCancellation() {
      this.processing = true;

      let formData = {
        type: 3,
        name: this.form.name,
        email: this.form.email,
        clientId: this.form.clientId,
        description: this.form.description,
      };

      axios
        .post("/api/create-ticket", formData)
        .then((res) => {
          console.log(res);
          let getFolio = res.data[0].folio;

          this.$alert("El folio generado es: " + getFolio);

          this.resetInput();

          this.processing = false;
        })
        .catch((error) => {
          console.log(error);

          this.$alert(
            "El ticket no pudo ser registrado, intentelo nuevamente." + error
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },

    onSubmitComplaint() {
      this.processing = true;

      let formData = {
        type: 2,
        name: this.form.name,
        email: this.form.email,
        description: this.form.description,
      };

      axios
        .post("/api/create-ticket", formData)
        .then((res) => {
          console.log(res);
          let getFolio = res.data[0].folio;

          this.$alert("El folio generado es: " + getFolio);

          this.resetInput();

          this.processing = false;
        })
        .catch((error) => {
          console.log(error);

          this.$alert(
            "El ticket no pudo ser registrado, intentelo nuevamente." + error
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },

    onSubmitComments() {
      this.processing = true;

      let formData = {
        type: 1,
        name: this.form.name,
        email: this.form.email,
        description: this.form.description,
      };

      axios
        .post("/api/create-ticket", formData)
        .then((res) => {
          console.log(res);
          let getFolio = res.data[0].folio;

          this.$alert("El folio generado es: " + getFolio);

          this.resetInput();

          this.processing = false;
        })
        .catch((error) => {
          console.log(error);

          this.$alert(
            "El ticket no pudo ser registrado, intentelo nuevamente." + error
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
}

.mailbox {
  padding-top: 100px;
  height: 100vh;
  background: #000000;
  background: linear-gradient(to top, #000000, #fecd00);
}

.mailbox .overall-content>* {
  color: #000000;
  z-index: 999;
}

.mailbox .overall-content .tab-list {
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 25px 0 0 25px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.mailbox .overall-content .tab-list .tab-item {
  /* background: rgba(254, 205, 0, 0.35); */
  /* background: rgba(0, 0, 0, 0.35); */
  border: 1px #fecd00 solid;
  border-left: 5px solid #fecd00;
  /* box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35); */
  padding: 10px 17.5px;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 0.4em;

  line-height: 0.6em;
  letter-spacing: 0.04em;
}

.mailbox .overall-content .tab-list .tab-item:hover,
.mailbox .overall-content .tab-list .tab-item.active {
  box-shadow: 0px 3px 3px 2px rgba(255, 255, 255, 0.15);
  border-left: 5px solid #ffffff;
}

.mailbox .overall-content .tab-list .tab-item__heading {
  font-weight: bold;
  font-size: 1em;
  color: #fecd00;
  margin: 0px;
}

.mailbox .overall-content .tab-list .tab-item__subheading {
  font-size: 0.6em;
  font-weight: 600;
  color: #ffffff;
}

.mailbox .overall-content .content-tab {
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /* border-radius: 0 25px 25px 0; */
  border: 1px solid rgba(255, 255, 255, 0.18);

  overflow-y: scroll;
  overflow-x: hidden;
}

.mailbox .overall-content .content-tab::-webkit-scrollbar {
  width: 6px;
}

.mailbox .overall-content .content-tab::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /* border-radius: 0 25px 25px 0; */
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.mailbox .overall-content .content-tab::-webkit-scrollbar-thumb {
  background-color: #fecd00;
  border-radius: 20px;
  border: 1px solid #ffffff;
}

.mailbox .overall-content .content-tab>* {
  color: #ffffff;
}

.mailbox .overall-content .content-tab h3 {
  font-size: 1.5rem;
}

.mailbox .overall-content .content-tab small {
  font-size: 0.8rem;
}

.mailbox .overall-content .content-tab .comments-section input,
.mailbox .overall-content .content-tab .comments-section textarea,
.mailbox .overall-content .content-tab .comments-section select {
  height: 2em;
  padding: 5px 10px;
  border-radius: 5px;
}

.mailbox .overall-content .content-tab .folio-locator input {
  height: 2em;
  padding: 5px 10px;
}

.mailbox .overall-content .content-tab .comments-section button,
.mailbox .overall-content .content-tab .folio-locator button {
  width: 30%;
  height: 2em;
  padding: 5px 10px;

  background: #fecd00;
  color: #000000;
  font-weight: 600;
  border: none;
}

/* Effect background */
.mailbox .area {
  width: 100%;
}

.mailbox .area .circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mailbox .area .circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(245, 205, 0, 0.6);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.mailbox .area .circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.mailbox .area .circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.mailbox .area .circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.mailbox .area .circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.mailbox .area .circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.mailbox .area .circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.mailbox .area .circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.mailbox .area .circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.mailbox .area .circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.mailbox .area .circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.bs-wizard {
  margin-top: 40px;
}

/*Form Wizard*/
.bs-wizard {
  border-bottom: solid 1px #e0e0e0;
  padding: 10px 0 10px 0;
}

.bs-wizard>.bs-wizard-step {
  padding: 0;
  position: relative;
}

.bs-wizard>.bs-wizard-step .bs-wizard-stepnum {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 5px;
}

.bs-wizard>.bs-wizard-step .bs-wizard-info {
  color: #999;
  font-size: 14px;
}

.bs-wizard>.bs-wizard-step>.bs-wizard-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: #fecd00;
  top: 45px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}

.bs-wizard>.bs-wizard-step>.bs-wizard-dot:after {
  content: " ";
  width: 14px;
  height: 14px;
  background: #000000;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.bs-wizard>.bs-wizard-step>.progress {
  position: relative;
  border-radius: 0px;
  height: 8px;
  box-shadow: none;
  margin: 20px 0;
}

.bs-wizard>.bs-wizard-step>.progress>.progress-bar {
  width: 0px;
  box-shadow: none;
  background: #fecd00;
}

.bs-wizard>.bs-wizard-step.complete>.progress>.progress-bar {
  width: 100%;
}

.bs-wizard>.bs-wizard-step.active>.progress>.progress-bar {
  width: 50%;
}

.bs-wizard>.bs-wizard-step:first-child.active>.progress>.progress-bar {
  width: 0%;
}

.bs-wizard>.bs-wizard-step:last-child.active>.progress>.progress-bar {
  width: 100%;
}

.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot {
  background-color: #f5f5f5;
}

.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot:after {
  opacity: 0;
}

.bs-wizard>.bs-wizard-step:first-child>.progress {
  left: 50%;
  width: 50%;
}

.bs-wizard>.bs-wizard-step:last-child>.progress {
  width: 50%;
}

.bs-wizard>.bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

/**
  * Flip to square
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes flip-to-square {
  0% {
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }

  50%,
  75% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}

@keyframes flip-to-square {
  0% {
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }

  50%,
  75% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
}

.flip-to-square {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 60px;
  width: 60px;
  z-index: 999;
}

.flip-to-square div {
  -webkit-animation: flip-to-square 1.5s calc(var(--delay) * 1s) infinite backwards;
  animation: flip-to-square 1.5s calc(var(--delay) * 1s) infinite backwards;
  background-color: #fecd00;
}

.flip-to-square div:nth-child(1) {
  --delay: 0.1;
}

.flip-to-square div:nth-child(2) {
  --delay: 0.2;
}

.flip-to-square div:nth-child(3) {
  --delay: 0.3;
}

.flip-to-square div:nth-child(4) {
  --delay: 0.4;
}

.flip-to-square div:nth-child(5) {
  --delay: 0.5;
}

.flip-to-square div:nth-child(6) {
  --delay: 0.6;
}

.flip-to-square div:nth-child(7) {
  --delay: 0.7;
}

.flip-to-square div:nth-child(8) {
  --delay: 0.8;
}

.flip-to-square div:nth-child(9) {
  --delay: 0.9;
}
</style>
