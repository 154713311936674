import { render, staticRenderFns } from "./mailbox.vue?vue&type=template&id=cc82f42c&scoped=true&"
import script from "./mailbox.vue?vue&type=script&lang=js&"
export * from "./mailbox.vue?vue&type=script&lang=js&"
import style0 from "./mailbox.vue?vue&type=style&index=0&id=cc82f42c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc82f42c",
  null
  
)

export default component.exports